import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ExampleCard from './components/ExampleCard';
import SkillRatings from './components/SkillRatings';
import JobRadarChart from './components/JobRadarChart';
import KeywordTabs from './components/KeywordTabs'
import YourSkills from './components/YourSkills'
import LoadingPanel from './components/LoadingPanel'
import CertificationPanel from './components/CertificationPanel'
import WordCount from './components/WordCount'
import HeaderModals from './components/HeaderModals';
import Auth from '@aws-amplify/auth'
import axios from 'axios';
import Footer from './components/Footer';
import ColorStatesFullWidth from '../../shared/components/ColorStatesFullWidth';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-171697858-1');


class Example extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      stars: '         ',
      jobs:'          ',
      keywords:[['',''],['',''],['',''],['',''],['','']],
      known_skills:[['',''],['',''],['',''],['',''],['',''],['','']],
      certification:'             ',
      word_count:0,
      maps:['Plumber','Natural Language Processing','Computer Scientist',"Profesor"],
      loading: true
    };
    const https = require('https');
    const agent = new https.Agent({
      rejectUnauthorized: false
    });



  const loadResume = () => {

    Auth.currentCredentials()
      .then(d => {
      //  console.log("API: got Credential");

        const formData = new FormData();
        formData.append('resumeFile', "");
        formData.append('resumeName', "");
        formData.append('resumeType', "" );


      const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
         }
        }

        axios({
          method: 'get',
          url: process.env.REACT_APP_apiEndpoint + '/statistics/'+this.props.match.params.hashid,
          data: formData,
          resumeid:'cv.pdf',
          headers: {
            'Content-Type': 'application/json'
          },
          }).then((response) => {

            var mydata=response.data;


            this.setState({stars:mydata['stars'],maps:[mydata['stars'][0][0],mydata['stars'][1][0],mydata['stars'][2][0],mydata['stars'][3][0]],jobs:mydata['pie_data'],keywords:mydata['keywords'],known_skills:mydata['known_skills'],certifications:mydata['certifications'],word_count:mydata['word_count'],loading:false});

          }).catch(error => {

            this.setState({error:true});
        });

        //console.log("API: end");;
      })
      .catch(e => {
        this.setState({error:true});
      });
    //this.setState({redirect: true});
  };
      loadResume();
  }

  render() {

if (this.state.loading){
  ReactGA.event({
  category: 'Loading',
  action: 'Analysis'
});
return(<LoadingPanel /> );
}
if (this.state.error) {
  ReactGA.event({
  category: 'Error',
  action: 'Analysis'
});
  return   <ColorStatesFullWidth
    />
}
ReactGA.pageview('Analysis');
      return (
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Resume Analysis</h3>

            </Col>
          </Row>

          <Row>

            <SkillRatings stars={this.state.stars} />
            <JobRadarChart jobs={this.state.jobs}/>
            <YourSkills skills={this.state.known_skills} />

</Row>
<Row>
               <KeywordTabs keywords={this.state.keywords}/>
               <CertificationPanel certifications={this.state.certifications}/>
               <HeaderModals maps = {this.state.maps}/>
<WordCount count={this.state.word_count}/>


          </Row>
            <Footer />
        </Container>
);
 }

}
export default Example;

import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip, ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';


class JobRadarChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      jobs: '         '
    };
    }

  render() {

    var data = [
      {
        subject: this.props.jobs[0][0], A: this.props.jobs[0][1],
      },
      {
        subject: this.props.jobs[1][0], A: this.props.jobs[1][1],
      },
      {
        subject: this.props.jobs[2][0], A: this.props.jobs[2][1],
      },
      {
        subject: this.props.jobs[3][0], A: this.props.jobs[3][1],
      },
      {
        subject: this.props.jobs[4][0], A: this.props.jobs[4][1],
      },
      {
        subject: this.props.jobs[5][0], A: this.props.jobs[5][1],
      },
      {
        subject: this.props.jobs[6][0], A: this.props.jobs[6][1],
      },
      {
        subject: this.props.jobs[7][0], A: this.props.jobs[7][1],
      },
      {
        subject: this.props.jobs[8][0], A: this.props.jobs[8][1],
      },
    ];
      return (
        <Col xs={12} md={12} lg={6} xl={4}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Resume Balance</h5>
                <h5 className="subhead">How much your resume focuses in each industry</h5>
              </div>
              <div dir="ltr">
                <ResponsiveContainer height={320}>
                  <RadarChart data={data} outerRadius={80}>
                    <PolarGrid strokeDasharray="3 3" />
                    <PolarAngleAxis dataKey="subject" />
                    <Tooltip formatter={(value,name) => ['','']} separator='' />
                    <Radar name="" dataKey="A" stroke="#3ea3fc" fill="#70bbfd" fillOpacity={0.7} />

                  </RadarChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </Col>
);
 }

}
export default JobRadarChart;

/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Col, Row, Container,Button, ButtonToolbar  } from 'reactstrap';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './components/Header';
import Footer from './components/Footer';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';
import { Link, Redirect } from 'react-router-dom';


import ReactGA from 'react-ga';
ReactGA.initialize('UA-171697858-1');
ReactGA.pageview('About');
const logo = `${process.env.PUBLIC_URL}/img/landing/logo.svg`;





class About extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    theme: ThemeProps.isRequired,
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };


  state = {
    documentName: "",
    documentFile: "",
    response: ""
  };

  render() {


    return (
      <div className="landing">
        <div className="landing__menu">
          <Container>
            <Row>
              <Col md={12}>
                <div className="landing__menu-wrap">
                  <p className="landing__menu-logo">
                    <Link to="/"><img src={logo} alt="Resume Analysis and Analytics" /> </Link>
                  </p>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Header />

        <p className="landing__footer">
        <p className="landing__header-subhead">
Resumehop was founded to help give job seekers a fair shot in the current job market. Most job seekers have applied to the perfect job only to never hear back from the company.
<br/><br/>
How? Why? Well the truth is, they were probably rejected automatically by a computer algorithm. With the advent of online job applications, companies need to quickly filter job applicants without looking at them individually.
<br/>
<br/>
What does a computer think of your resume? Before you couldn’t know! Resumehop puts the power in your hands. You may think your resume is oriented to one industry but really the computer is picking up on non-relevant skills.
<br/>
<br/>
See what the Computer sees and try your resume today. No registration needed. This service is free. If you want to see typical results first, try our sample resume below.

        </p>
        <Link className="landing__btn landing__btn--header" to={{ pathname: "/analysis/"+process.env.REACT_APP_sampleHash}}>
        Try our sample resume
        </Link>
        </p>

        <Footer />

      </div>
    );
  }
}

export default connect(state => ({ theme: state.theme }))(About);

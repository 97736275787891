/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Col, Row, Container,Button, ButtonToolbar  } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './components/Header';
import Footer from './components/Footer';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';
import { Link, Redirect } from 'react-router-dom';
import Storage from "@aws-amplify/storage";
import Auth from '@aws-amplify/auth'
import axios from 'axios';
import ColorStatesFullWidth from '../../shared/components/ColorStatesFullWidth';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-171697858-1');
ReactGA.pageview('Homepage');
const logo = `${process.env.PUBLIC_URL}/img/landing/logo.svg`;





class Landing extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    theme: ThemeProps.isRequired,
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };


  state = {
    documentName: "",
    documentFile: "",
    response: ""
  };

  uploadDocument = () => {
  //  this.setState({ response: "Uploading file. Please wait..." + this.upload.files[0].type });
  this.setState({ response: "Uploading file. Please wait..."});

    Auth.currentCredentials()
      .then(d => {

        const formData = new FormData();
        formData.append('resumeFile', this.upload.files[0]);
        formData.append('resumeName', this.upload.files[0].name);
        formData.append('resumeType', this.upload.files[0].type );

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
         }
        }
        axios({
          method: 'post',
          url: process.env.REACT_APP_apiEndpoint + '/mapping',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          }).then((response) => {
            //console.log("X API Axios: success 1");
          //  console.log("1: " + response.data.statusCode);
        //    console.log("2: " + response.data.hash_number);
        //    console.log("API: success upload");
            if(response.data.hash_number!==undefined){
              this.setState({redirect:true,hash_number:response.data.hash_number});
            }
            else{
              this.setState({error:true,redirect:false});
            }
          }).catch(error => {
                this.setState({error:true});
          //  console.log("API: fail");
          //  console.log(error);
        });

      //  console.log("API: end");;
      })
      .catch(e => {
        console.log('error1: ', e);
          this.setState({error:true});

    });
    //this.setState({redirect: true});
  };

  render() {
    if (this.state.redirect) {
    //  console.log('Redirecting');
      return <Redirect to={{ pathname: "/analysis/"+this.state.hash_number}}/>;
    }
    if (this.state.error) {
      ReactGA.event({
      category: 'Error',
      action: 'Home'
    });
      return   <ColorStatesFullWidth
        />
    }
    return (
      <div className="landing">
        <div className="landing__menu">
          <Container>
            <Row>
              <Col md={12}>
                <div className="landing__menu-wrap">
                  <p className="landing__menu-logo">
                    <Link to="/"><img src={logo} alt="Resume Analysis and Analytics" /> </Link>
                  </p>
                  <nav className="landing__menu-nav">

                  <Link className="landing__btn landing__btn--header" to={{ pathname: "/analysis/"+process.env.REACT_APP_sampleHash}}>
                  Try our sample resume
                  </Link>

                  </nav>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Header />

        <p className="landing__footer">
        <p className="landing__header-subhead">Upload your resume in
        <span class='panel__label badge badge-secondary'>.docx</span> or<span class='panel__label badge badge-secondary'>.pdf</span> format.</p>
        </p>


        <div className="landing__footer">
          <input
            type="file"
            accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            style={{ display: "none" }}
            ref={ref => (this.upload = ref)}
            onChange={e => {
              this.setState({
                documentFile: this.upload.files[0],
                documentName: this.upload.files[0].name
              });
              this.uploadDocument();
            }
            }
          />
          <button className="landing__btn landing__btn--header"
            onClick={e => {
              this.upload.value = null;
              this.upload.click();
            }}
            loading={this.state.uploading}>
              Upload Your Resume
          </button>
          {!!this.state.response && <div>{this.state.response}</div>}
        </div>


        <Footer />
      </div>
    );
  }
}

export default connect(state => ({ theme: state.theme }))(Landing);

import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import PropTypes from 'prop-types';

const logo_head = `${process.env.PUBLIC_URL}/img/landing/resumehop-logo.svg`;
const logo = `${process.env.PUBLIC_URL}/img/landing/logo.svg`;
const background = `${process.env.PUBLIC_URL}/img/landing/header_bg.png`;

const Header = ({ onClick }) => (
  <div className="landing__header" style={{ backgroundImage: `url(${background})` }}>
    <Container>
    <Row>
      <img className="pricing-card__img" src={logo_head} alt="" />

    </Row>

      <Row>
        <Col md={12}>
          <h2 className="landing__header-title">Contact Us<br />
          </h2>



        </Col>
      </Row>
    </Container>
  </div>
);

Header.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Header;

import React from 'react';
import { Progress } from 'reactstrap';

import PropTypes from 'prop-types';
import Panel from '../../../shared/components/Panel';

const SkillRatings = ({ stars }) => (

  <Panel xs={12} md={12} lg={12} xl={4} title={'Knowledge'} subhead="How do your skills rank?">
    <div className="progress-wrap progress-wrap--small">
      <p>{stars[0][0]}</p>
      <Progress value={stars[0][1]}></Progress>
    </div>
    <div className="progress-wrap progress-wrap--small">
      <p>{stars[1][0]}</p>
      <Progress value={stars[1][1]}></Progress>
    </div>
    <div className="progress-wrap progress-wrap--small">
      <p>{stars[2][0]}</p>
      <Progress value={stars[2][1]}></Progress>
    </div>
    <div className="progress-wrap progress-wrap--small">
      <p>{stars[3][0]}</p>
      <Progress value={stars[3][1]}></Progress>
    </div>
    <div className="progress-wrap progress-wrap--small">
      <p>{stars[4][0]}</p>
      <Progress value={stars[4][1]}></Progress>
    </div>
    <div className="progress-wrap progress-wrap--small">
      <p>{stars[5][0]}</p>
      <Progress value={stars[5][1]}></Progress>
    </div>
  </Panel>
);



export default SkillRatings;

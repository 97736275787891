/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Col, Row, Container,Button, ButtonToolbar  } from 'reactstrap';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './components/Header';
import Footer from './components/Footer';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';
import { Link, Redirect } from 'react-router-dom';


import ReactGA from 'react-ga';
ReactGA.initialize('UA-171697858-1');
ReactGA.pageview('Contact');
const logo = `${process.env.PUBLIC_URL}/img/landing/logo.svg`;





class Contact extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    theme: ThemeProps.isRequired,
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };


  state = {
    documentName: "",
    documentFile: "",
    response: ""
  };

  render() {


    return (
      <div className="landing">
        <div className="landing__menu">
          <Container>
            <Row>
              <Col md={12}>
                <div className="landing__menu-wrap">
                  <p className="landing__menu-logo">
                    <Link to="/"><img src={logo} alt="Resume Analysis and Analytics" /> </Link>
                  </p>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Header />

        <p className="landing__footer">
        <p className="landing__header-subhead">
Have a question, comment or inquiry? Contact us at:
<br/><br/>
info@languagekits.com


        </p>

        </p>

        <Footer />

      </div>
    );
  }
}

export default connect(state => ({ theme: state.theme }))(Contact);

import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Card, CardBody, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { FullWideNotification } from './Notification';

class ColorStatesFullWidth extends PureComponent {
  static propTypes = {
    showNotification: PropTypes.func.isRequired,
  };

  show = (color) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message="Learning day desirous informed expenses material returned six the.
                                        She enabled invited exposed him another."
      />,
      position: 'full',
    });
  };

  render() {

    return (
      <div>
      <FullWideNotification
        color={'danger'}
        message="An Error has occured while processing your resume. Please make sure it is a .pdf or .docx file"
      />

      <a href='http://www.resumehop.com'>Return to the Homepage </a>

      </div>
    );
  }
}

export default ColorStatesFullWidth;

import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';

const WordCount = ({ count }) => (
  <Col md={12} xl={3} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__booking-card">
        <div className="dashboard__booking-total-container">
          <h5 className="dashboard__booking-total-title dashboard__booking-total-title--green">
            {count}
          </h5>

        </div>
        <h5 className="dashboard__booking-total-description">Total Words</h5>
        <h5 className="bold-text">If this is lower than you expect, ATS resume systems may not be able to read your resume. Consider changing the format.</h5>
      </CardBody>
    </Card>
  </Col>
);

export default WordCount;

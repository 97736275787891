import React, { PureComponent } from 'react';
import PanelBasic from './PanelBasic'
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';

class LoadingPanel extends PureComponent {
  constructor() {
    super();
    this.state = {
      time: 0,
    };
   let timer=  setInterval(() => {
      this.setState({time:this.state.time+1});
    
      if (this.state.time >= 20) {
      clearInterval(timer);
      }
    }, 1000);
  }



render() {

  const { activeTab } = this.state;
  return (
    <PanelBasic xs={12} md={12} lg={6} color="success" title="Analyzing your resume against the latest jobs, industry skills, and certifications.  ">
    <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
  <Col md={12} lg={6}>
    <Card>
      <CardBody>
  <div className="progress-wrap progress-wrap--big">
    <Progress animated value={this.state.time*5} />
      <p className="landing__header-subhead">
    This may take up to 20 seconds.
    </p>
<p className="landing__header-subhead">
     Please wait ....
     </p>
  </div>
  </CardBody>
</Card>
</Col>


     </div>
    </PanelBasic>
  );
}
}
export default LoadingPanel;

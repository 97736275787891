import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Panel from '../../../shared/components/Panel';
const ExampleCard = ({ certifications }) => (
  <Panel xs={12} md={12} lg={12} xl={4} title={'Certifications'} subhead="What certifications would help you the most in your career ">
  <tbody>
    {certifications.map(item => <div key={item.id} className="progress-wrap progress-wrap--small">
      <p><b>{item[0]}</b></p>
      <ul>
      {item[1].map(job =>
      <li>{job[0]} : {job[1]}% of jobs</li>
    )}
      </ul>
    </div>)}
  </tbody>


  </Panel>
);

export default ExampleCard;

import React, { PureComponent } from 'react';
import TopbarProfile from './TopbarProfile';

class Topbar extends PureComponent {

  render() {

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__right">
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;

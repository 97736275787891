import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';


const YourSkills = ({skills}) => (

  <Col xs={12} md={12} lg={12} xl={4}>
    <Card>
      <CardBody className="pricing-card pricing-card--primary">
        <div className="pricing-card__body">
          <h3 className="pricing-card__plan">Your Marketable Skills</h3>
          <hr />
          <p className="pricing-card__feature">{skills[0][0]}</p>
          <p className="pricing-card__feature">{skills[1][0]}</p>
          <p className="pricing-card__feature">{skills[2][0]}</p>
          <p className="pricing-card__feature">{skills[3][0]}</p>
          <p className="pricing-card__feature">{skills[4][0]}</p>
          <p className="pricing-card__feature">{skills[5][0]}</p>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default YourSkills;

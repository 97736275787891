import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';

class KeywordTabs extends PureComponent {


  constructor() {
    super();
    this.state = {
      activeTab: '1',
    };
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

extractKeyword(index){
  return   <span><div className="card__title">
      <h5 className="bold-text">Improvements for {this.props.keywords[index-1][0]}</h5>
      <h5 className="subhead">These words did not appear in your resume</h5>
    </div>
    <ol className="list">
      <li>{this.props.keywords[index-1][1][0]}</li>
      <li>{this.props.keywords[index-1][1][1]}</li>
      <li>{this.props.keywords[index-1][1][2]}</li>
      <li>{this.props.keywords[index-1][1][3]}</li>
      <li>{this.props.keywords[index-1][1][4]}</li>
    </ol>
    </span>

}
  render() {

    const { activeTab } = this.state;
    return (
      <Col xs={12} md={12} lg={12} xl={4}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Improve Your Resume</h5>
              <h5 className="subhead">Consider adding these keywords </h5>
            </div>
            <div className="tabs tabs--vertical">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {this.props.keywords[0][0]}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {this.props.keywords[1][0]}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                    {this.props.keywords[2][0]}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => {
                        this.toggle('4');
                      }}
                    >
                    {this.props.keywords[3][0]}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                      {this.extractKeyword(1)}
                  </TabPane>
                  <TabPane tabId="2">

                    {this.extractKeyword(2)}

                  </TabPane>
                  <TabPane tabId="3">
                  {this.extractKeyword(3)}
                  </TabPane>
                  <TabPane tabId="4">
                      {this.extractKeyword(4)}
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default KeywordTabs;

import React from 'react';
import {
  ButtonToolbar, Card, CardBody, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Modal from '../../../shared/components/Modal';

const HeaderModals = ({ maps }) => (
  <Col md={12} lg={12} xl={4}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Find where the jobs are!</h5>
          <h5 className="subhead">Click to see a map</h5>
        </div>
        <ButtonToolbar>
          <Modal
            color="success"
            title={"Job Postings for "+maps[0]}
            header
            btn={maps[0]}

          />
          <br/>
          <Modal
            color="success"
            title={"Job Postings for "+maps[1]}
            header
            btn={maps[1]}

          />
          <br/>
          <Modal
            color="success"
            title={"Job Postings for "+maps[2]}
            header
            btn={maps[2]}

          />
          <br/>
          <Modal
            color="success"
            title={"Job Postings for "+maps[3]}
            header
            btn={maps[3]}

          />
        </ButtonToolbar>
      </CardBody>
    </Card>
  </Col>
);

HeaderModals.propTypes = {
  t: PropTypes.func.isRequired,
};

export default HeaderModals;

import Amplify from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';

export function configureAmplify() {
  Amplify.configure(
  {
    Auth: {
      // Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_identityPoolId,
      // Amazon Cognito Region
      region: process.env.REACT_APP_region,
    },
    API: {
      endpoints: [
      {
          name: process.env.REACT_APP_apiName,
          endpoint: process.env.REACT_APP_apiEndpoint
      }
      ]
    },
    Storage: {
      bucket: process.env.REACT_APP_Bucket_name,
      region: process.env.REACT_APP_region,
      identityPoolId: process.env.REACT_APP_identityPoolId
    }
  }
 );
}

import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const background = `${process.env.PUBLIC_URL}/img/landing/footer_bg.png`;

const Footer = () => (
  <footer className="landing__footer">
    <img className="landing__footer-background" src={background} alt="" />
    <Container>
      <Row>
      <Col md={12}>
      <p className="landing__footer-text">resumehop
        <b><a target="_blank" rel="noopener noreferrer" href="http://languagekits.com"> &copy; LanguageKits </a></b>
        |
          <b><a href="/about"> About Us </a></b>
          |
                <b><a href="/contact"> Contact</a></b>
      </p>
      </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
